import { CODES } from "./decode-id";

class BarcodeScannerHandler {
  constructor() {
    this.timer = null;
    this.prevKey = null;
    this.receiving = false;
    this.barcode = [];
    this.codeLength = 0;

    // Bind the handler to ensure 'this' context
    this.handleKeyEvent = this.handleKeyEvent.bind(this);

    // Add event listener
    document.addEventListener("keypress", this.handleKeyEvent);

    // Prevent shortcuts
    document.addEventListener("keydown", this.preventShortcuts);
  }

  preventShortcuts(event) {
    // Prevent common shortcuts (Ctrl/Cmd + key)
    if (event.ctrlKey || event.metaKey) {
      // Allow most used operations
      if (!["c", "v"].includes(event.key.toLowerCase())) {
        event.preventDefault();
        return false;
      }
    }

    // Prevent other common shortcuts
    const preventKeys = [
      "F5", // Refresh
      "F11", // Full screen
      "F12", // Dev tools
      "Alt", // Menu
      "Tab", // Tab switching
      "Escape", // Escape
      "Browser", // Browser keys
    ];

    if (preventKeys.some((key) => event.key.startsWith(key))) {
      event.preventDefault();
      return false;
    }
  }

  handleKeyEvent(event) {
    try {
      // Timeout check (300ms)
      if (this.timer && Date.now() - this.timer > 300) {
        if (this.receiving) {
          this.emitBarcodeScanFailed(this.barcode.join(""));
        }
        this.clearBarcode();
      }

      // Use the actual character that would be input
      const char = event.key;

      // Check if it's a character input
      if (char.length === 1 || char === "Enter") {
        // Second character scanned - start of new scan
        if (!this.receiving && this.timer && Date.now() - this.timer < 250) {
          this.receiving = true;
          this.emitBarcodeScanLoading();

          if (this.prevKey !== null) {
            this.codeLength += 1;
            this.barcode.push(this.prevKey);
          }
          this.barcode.push(char);
          this.codeLength += 1;
          this.prevKey = null;
        }

        // Existing scan still going
        else if (this.receiving) {
          this.barcode.push(char);
          this.codeLength += 1;

          // Check for Enter key and minimum length
          if (char === "Enter" && this.codeLength > 4) {
            const barcodeString = this.barcode.slice(0, -1).join(""); // Remove Enter key
            this.emitBarcodeScan(barcodeString);
            this.clearBarcode();
            event.preventDefault();
            return false;
          }
        }
        // First character of potential scan
        else {
          this.prevKey = char;
        }
      }

      this.timer = Date.now();
    } catch (error) {
      console.error("Barcode scanner error:", error);
    }
  }

  clearBarcode() {
    this.barcode = [];
    this.receiving = false;
    this.prevKey = null;
    this.codeLength = 0;
    this.timer = null;
  }

  emitBarcodeScan(barcode) {
    console.log("emitting", barcode);
    const event = new CustomEvent("onbarcode", {
      detail: { data: barcode },
    });
    window.dispatchEvent(event);
  }

  emitBarcodeScanLoading() {
    const event = new CustomEvent("onbarcodeloading", {
      detail: true,
    });
    window.dispatchEvent(event);
  }

  emitBarcodeScanFailed(barcode) {
    const event = new CustomEvent("onbarcodescanfailed", {
      detail: { data: barcode },
    });
    window.dispatchEvent(event);
  }

  // Cleanup method
  destroy() {
    document.removeEventListener("keydown", this.handleKeyEvent);
  }
}

export default BarcodeScannerHandler;
